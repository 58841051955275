import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import LocationNav from "../components/locationNav";
function storyTemplate({ pageContext, data }) {
  const { contentfulStory } = data;
  const { countryFull, stateFull, state, country, edit } = contentfulStory;
  const { html } = edit.childMarkdownRemark;

  const countryQueryParams = `country=${country}`;
  const countryLinkPath = `/stories/search?${countryQueryParams}`;
  const countryStateQueryParams = `country=${country}&state=${state}`;
  const countryStateLinkPath = `/stories/search?${countryStateQueryParams}`;

  const { statePath, countryPath } = pageContext;

  const stateOrCountryPath =
    statePath || countryPath ? (
      <nav className="my-8 py-8 text-slate-500 border-t">
        <Link to={statePath || countryPath} className="text-xl">
          Read more stories from {stateFull || countryFull}
        </Link>
      </nav>
    ) : null;

  return (
    <Layout>
      <LocationNav />
      <article className="prose prose-slate">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </article>
      {stateOrCountryPath}
    </Layout>
  );
}

export default storyTemplate;

export const pageQuery = graphql`
  query StoryById($id: String!) {
    contentfulStory(id: { eq: $id }) {
      id
      countryFull
      country
      stateFull
      state
      edit {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
